.jumbotron{
    height:30vh;
    background: transparent !important;
    display:table;
    width: 100%;
}

.container{
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    width: 100%;
}